import React from "react";

const AdiPosiFitType = () => {
  return (
    <>
      <span className="font-bold text-blue-500">Adi</span>
      <span className="font-bold text-green-500">Posi</span>
      <span className="font-bold text-orange-500">Fit</span>
    </>
  );
};
export default AdiPosiFitType;
